<script setup lang="ts">
import { DialogClose, DialogContent, DialogDescription, DialogOverlay, DialogPortal, DialogRoot, DialogTitle, DialogTrigger } from 'radix-vue'

export interface Props {
   title?: string
   description?: string
}

const { description, title } = defineProps<Props>()

const emit = defineEmits<{
   close: []
   open: []
}>()

const { t } = useI18n()
</script>

<template>
   <DialogRoot @update:open="$event ? emit('open') : emit('close')">
      <DialogTrigger v-if="$slots.trigger">
         <slot name="trigger" />
      </DialogTrigger>

      <DialogPortal>
         <DialogOverlay class="fixed inset-0 z-30 bg-blue/80 before:absolute before:inset-0 before:bg-black/50" />
         <DialogContent
            class="fixed inset-0 z-50 grid place-items-center p-8"
            @escape-key-down="emit('close')"
         >
            <div class="relative flex max-h-full max-w-2xl  flex-col overflow-hidden whitespace-pre-wrap bg-gradient-to-bl from-blue to-black p-8 text-white shadow-lg md:p-16 xl:p-24">
               <DialogClose
                  class="absolute right-4 top-4 grid h-10 w-10 place-items-center rounded-full border-white text-2xl outline-none focus:border"
                  :aria-label="t('global.close')"
               >
                  <Icon name="heroicons-solid:x" />
               </DialogClose>

               <slot name="content">
                  <div class="flex-1 overflow-y-auto">
                     <DialogTitle class="mb-8 mt-2 text-2xl font-bold">
                        <slot name="title">
                           {{ title }}
                        </slot>
                     </DialogTitle>

                     <DialogDescription class="text-lg">
                        <slot name="description">
                           {{ description }}
                        </slot>
                     </DialogDescription>
                     <slot />

                     <div
                        v-if="$slots.footer"
                        class="mt-4 text-sm italic"
                     >
                        <slot name="footer" />
                     </div>
                  </div>
               </slot>
            </div>
         </DialogContent>
      </DialogPortal>
   </DialogRoot>
</template>
